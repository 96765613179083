import { newDate } from '@/utils/date';
import * as _dateFns from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ja } from 'date-fns/locale';

_dateFns.setDefaultOptions({
  // 日本語設定
  locale: ja,
  // 日曜始まり
  weekStartsOn: 0,
});

export const dateFns = _dateFns;

export const Format = {
  date: 'yyyy.MM.dd',
  dateJa: 'yyyy年MM月dd日',
  dateTime: 'yyyy.MM.dd HH:mm',
  dateTimeSlash: 'yyyy/MM/dd HH:mm',
  dateTimeRequest: 'yyyy-MM-dd HH:mm:ss',
  dateTimeJa: 'yyyy年MM月dd日 HH時mm分',
  dateTimeLotteryJa: 'yyyy年MM月dd日 HH:mm',
  dateTimeJaIncDOW: 'yyyy.MM.dd (E) HH:mm',
};

export type DateFormat = (typeof Format)[keyof typeof Format];
