'use client';

import iconCoinSmall from '@/assets/images/icons/coin-small.png';
import iconCard from '@/assets/images/menu/icon-card.png';
import iconCoinBlanc from '@/assets/images/menu/icon-coin-blanc.png';
import iconCoin from '@/assets/images/menu/icon-coin.png';
// import iconEc from '@/assets/images/menu/icon-ec.png';
import iconEdit from '@/assets/images/menu/icon-edit.png';
import iconHistory from '@/assets/images/menu/icon-history.png';
import iconLogin from '@/assets/images/menu/icon-login.png';
import iconLogout from '@/assets/images/menu/icon-logout.png';
import iconMailBox from '@/assets/images/menu/icon-mailbox.png';
// import iconNotReceived from '@/assets/images/menu/icon-not-received.png';
import iconPresent from '@/assets/images/menu/icon-present.png';
import iconQuestion from '@/assets/images/menu/icon-question.png';
import iconSerial from '@/assets/images/menu/icon-serial.png';
import iconSetting from '@/assets/images/menu/icon-setting.png';
import iconShipping from '@/assets/images/menu/icon-shipping.png';
import iconWChance from '@/assets/images/menu/icon-wchance.png';
import utilsStyles from '@/styles/modules/utils.module.scss';
import { formatCurrency } from '@/utils/formatters';
import { goMainOneTimeAuth } from '@/utils/functions';
import { atom, useAtom } from 'jotai';
import type { Route } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import IconTextUnit from '../../ui/IconTextUnit/IconTextUnit';
import LinkListItem from '../../ui/LinkListItem/LinkListItem';
import styles from './CommonMenu.module.scss';

// メニュー開閉
// NOTE: 共通処理に移動したい
const isMenuOpenedAtom = atom(false);
const useIsMenuOpenedAtom = () => useAtom(isMenuOpenedAtom);

export { useIsMenuOpenedAtom };

// コンポーネント
function CommonMenu({
  isOptionNotEntered = false,
  hasPendingEntry = false,
  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  hasAlertEcItem = false,
  onLogout,
  onSerialCode,
  userUnreadMessageBoxCount = 0,
  userName,
  userCoin,
  isFamikuji = false,
}: {
  isOptionNotEntered?: boolean;
  hasPendingEntry?: boolean;
  hasAlertEcItem?: boolean;
  onLogout: () => void;
  onSerialCode: () => void;
  userUnreadMessageBoxCount?: number;
  userCoin?: number;
  userName?: string;
  isFamikuji?: boolean;
}) {
  const router = useRouter();
  const [isMenuOpenedValue, setIsMenuOpened] = useIsMenuOpenedAtom();
  const nodeRef = useRef(null);

  return (
    <CSSTransition in={isMenuOpenedValue} timeout={200} classNames={styles} nodeRef={nodeRef} appear unmountOnExit>
      <div
        className={`${styles.componentStyle}`}
        onClick={() => {
          setIsMenuOpened(false);
        }}
        ref={nodeRef}
      >
        <div className={styles.container}>
          <section className={styles.profile}>
            <div className={styles.profileDetail}>
              <Link href={'/account/edit'} className={styles.profileName}>
                <span className={utilsStyles.textEllipsis}>{userName}</span>
                &nbsp;
                <img src={iconEdit.src} alt='Edit' className={utilsStyles.textIconSmall} />
              </Link>
            </div>
            <div className={utilsStyles.mtSmall}>
              <p className={styles.menuCoinLabel}>保有しているコイン</p>
              <div className={styles.menuCoin}>
                <IconTextUnit text={formatCurrency(userCoin ?? 0)} imgSrc={iconCoinSmall.src} unit={'coin'} />
              </div>
            </div>
          </section>
          <hr className={styles.hr} />
          <ul className={styles.menuList}>
            <li>
              <LinkListItem
                iconSrc={iconMailBox.src}
                onClick={() => {
                  router.push('/account/messages');
                }}
                text='メッセージボックス'
                showBadge={userUnreadMessageBoxCount > 0}
              />
            </li>
            <li>
              <LinkListItem
                iconSrc={iconPresent.src}
                onClick={() => {
                  router.push('/account/collection');
                }}
                text='オンラインくじ獲得景品一覧'
                showBadge={isOptionNotEntered}
              />
            </li>
            {/* <li>
              <LinkListItem
                iconSrc={iconEc.src}
                onClick={() => {
                  router.push('/e-commerce/digital-contents/purchase-history');
                }}
                text='デジタルコンテンツ購入商品一覧'
                showBadge={hasAlertEcItem}
              ></LinkListItem>
            </li> */}
            {/* {!isFamikuji && (
              <li>
                <LinkListItem
                  iconSrc={iconNotReceived.src}
                  onClick={() => {
                    router.push('/account/collection/hand-over');
                  }}
                  text='未受け取り景品一覧'
                />
              </li>
            )} */}
            <li>
              <LinkListItem
                iconSrc={iconWChance.src}
                onClick={() => {
                  router.push('/account/wchance-collection');
                }}
                text='Wチャンス賞チャレンジ履歴'
                showBadge={hasPendingEntry}
              />
            </li>
            <li>
              <LinkListItem
                iconSrc={iconCoin.src}
                onClick={() => {
                  router.push(isFamikuji ? ('/familymart/purchase' as Route) : '/account/purchase');
                }}
                text='コインを購入する'
              />
            </li>
            <li>
              <LinkListItem
                iconSrc={iconCoinBlanc.src}
                onClick={() => {
                  router.push('/account/coin-history');
                }}
                text='コイン消費履歴'
              />
            </li>
            <li>
              <LinkListItem
                iconSrc={iconCard.src}
                onClick={() => {
                  goMainOneTimeAuth({
                    redirectPath: `/${process.env.NEXT_PUBLIC_SERVICE_ID}/payment/cards`,
                  });
                }}
                text='お支払い方法'
              />
            </li>
            <li>
              <LinkListItem iconSrc={iconSerial.src} onClick={onSerialCode} text='シリアルコードの入力' />
            </li>
            <li>
              <LinkListItem
                iconSrc={iconHistory.src}
                onClick={() => {
                  goMainOneTimeAuth({
                    redirectPath: '/account/payment/history',
                  });
                }}
                text='決済履歴'
              />
            </li>
            <li>
              <LinkListItem
                iconSrc={iconShipping.src}
                onClick={() => {
                  goMainOneTimeAuth({
                    redirectPath: `/account/address?callback=${location.href}`,
                  });
                }}
                text='配送先の確認と変更'
              />
            </li>
            <li>
              <LinkListItem
                iconSrc={iconLogin.src}
                onClick={() => {
                  goMainOneTimeAuth({
                    redirectPath: '/account',
                  });
                }}
                text='ログイン情報設定'
              />
            </li>
            <li>
              <LinkListItem
                iconSrc={iconSetting.src}
                onClick={() => {
                  router.push('/account/notification');
                }}
                text='通知設定'
              />
            </li>
            <li>
              <LinkListItem
                iconSrc={iconQuestion.src}
                onClick={() => {
                  router.push('/faq');
                }}
                text='よくある質問'
              />
            </li>
            <li>
              <LinkListItem iconSrc={iconLogout.src} onClick={onLogout} text='ログアウト' />
            </li>
          </ul>
        </div>
      </div>
    </CSSTransition>
  );
}

export default CommonMenu;
